<template >
  <v-container>
    <div class="help">
      <v-row>
        <h1>Help</h1>
      </v-row>

      <br/>
      <v-tabs v-model="tabs" centered grow class="margin-top-bottom">
        <v-tab         >
          <h2>Customers help</h2>
        </v-tab>
        <v-tab         >
          <h2>Chefs help</h2>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-expansion-panels
                  v-model="panelCustomer"
                  multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header><h3>How does Kootto works for customers</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                1 Select a location, date, time and number of guests for your event<br/>
                2 Browse available menus<br/>
                3 Book your favourite menu and pay securely online<br/>
                4 The chef contacts you to confirm the details<br/>
                5 On the day, your chef arrives with all the ingredients<br/>
                6 The chef cooks in your kitchen and serves each dish<br/>
                7 Before leaving, the chef cleans the dishes.<br/>
<!--                8 You can leave the chef a review of your experience<br/>-->
                <br/>
                Our team are here to help . Contact Us<br/>
                <br/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3 >Why book a private chef ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Kootto service was created to allow you to receive at home, in a unique way. Our talented private chefs come to you and take care of everything. You just have to sit back, relax and enjoy a delicious meal with your guests, without constraint.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>What does the service include ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Purchase of raw materials (food Ingredients).<br/>
                Preparation of the food<br/>
                Service<br/>
                Cleaning<br/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>What extra services does the chef provide ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                The chef will provide a restaurant-level of service, including serving the food at the table.
                He will also clean your kitchen and wash the dishes after the meal, leaving them in the same condition as when they arrived.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Who are the chefs ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Our partner chefs are passionate about cooking and are dedicated to creating unique experiences for each event, which may be limited in a traditional restaurant setting.<br/><br/>
                The level of expertise and pricing vary among our chefs. Some of them come from gastronomic restaurants, while others have recently graduated from hospitality management programs or are seeking a new career path to pursue their passion for cooking.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>How are the chefs selected ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Chefs partner with Kootto by signing up on our platform. We ask them to provide us with information about their background and the services they offer, we verify their hygiene training and chef certification and then screen them to learn more about their culinary background and expertise.<br/><br/>
                Each chef has their own level of expertise. Therefore, each chef sets their own pricing. Kootto ensures that all of our partner chefs meet our quality standards and that the quality of their service is reflected in their pricing.
              </v-expansion-panel-content>
            </v-expansion-panel>

<!--            <v-expansion-panel>-->
<!--              <v-expansion-panel-header><h3>How do the reviews work?</h3></v-expansion-panel-header>-->
<!--              <v-expansion-panel-content>-->
<!--                The host of the dinner and all the guests can leave their reviews about the chef. All the reviews are published, whether they are positive or negative.<br/><br/>-->
<!--                The day after the service has been offered, you will receive an email asking to give your opinion about the chef, while leaving a comment. This review is public and contributes to the overall reputation of the chef.-->
<!--              </v-expansion-panel-content>-->
<!--            </v-expansion-panel>-->

            <v-expansion-panel>
              <v-expansion-panel-header><h3>How do I get in contact with the chef ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Once you have booked the chef you will be able to get in contact and share information.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><h3>Do chefs cater for gluten free, vegetarians, vegan etc ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Yes, of course, you will be able to discuss with the chef about various dietary options.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><h3>What is the cancellation policy for Kootto ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                To cancel or change a reservation, contact us as soon as possible by phone  0415788815 or email us via the form contact us <br/>

                We will do our best to accommodate your requests. However, the chefs prepare in advance their services and this implies certain non-refundable costs.<br/>
                In accordance with our cancellation policy if you cancel the service {{this.$store.state.parameters.find(value => value.name.name=="REJECT_DELAY").value}} days before the scheduled date of the service, you will be fully refunded. No refund is possible if you cancel less than 10 days before the scheduled date of the service.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><h3>How to change the number of guests?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                To change a booking, contact us as soon as possible by phone 0415788815 or email us via the contact form. We will do our best to accommodate your requests. However, the chefs prepare in advance the services and this implies certain non-refundable costs.<br/>
                <br/>
                If you want to add extra guests, you should notify Kootto ahead and will have to agree to the additional payment related.<br/>
                <br/>
                Reducing the number of guests is equivalent to our cancellation policy for a service. If you cancel the presence of one or more guests over {{this.$store.state.parameters.find(value => value.name.name=="NO_CANCELLATION_PERIOD").value}} days before the scheduled date of the services, you will be fully reimbursed for the pro-rata amount. No refund is possible if you cancel the presence of one or more guests less than 10 days before the scheduled date of the services.<br/>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><h3>One of the guests has an allergy, how do I let the chef know ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Before finalizing the booking you can leave a note for the chef about your special requirements including your allergy or dietary restrictions.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><h3>I forgot my password</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                No worries, please click on this link (I forgot my password). You will be asked to enter your email address and we will send you an email with the instructions to reset your password.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><h3>How much notice does kootto’s chef need before an event ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                In order to get the best service and allow time for our chefs to organize themselves a minimum of 72 hours is necessary.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <v-tab-item>
          <v-expansion-panels
                  v-model="panelChef"
                  multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header><h3>How does Kootto works for Chefs</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                1 Register and complete your profile <br/>
                2 Create your menus<br/>
                3 Upload your photos<br/>
                4 Select your availability<br/>
                5 After approval of your profile and menus, you will be live<br/>
                6 Wait for booking<br/>
                <br/>
                Our team are here to help. Contact Us<br/>
                <br/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Why should I create a profile ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Creating a profile allows you to set your own schedule and work whenever you want.
                The freedom to create your own menus and update them as many times as you like.
                Reach more potential customers and share your passion.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Who can be chef ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Chefs have to be professionals and have prior experience or training.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>How do I create my chef profile ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Sing up as a chef and follow the prompt.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>What should I include in the price of my menu ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Menu design<br/>
                Purchase of raw material<br/>
                Time spent<br/>
                Transport<br/>
                Cooking at guest’s house<br/>
                Service<br/>
                Cleaning<br/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>What process should I follow to receive a booking ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                To receive a booking it is essential to have completed your chef profile and set your availability in your calendar. Once approved by Kootto’s team you will appear on the platform and customers will be able to request your services.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>How can I get in contact with my client ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Once the client has booked your service, you will be able to get in contact and share information.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Do I need a licence ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Please contact your local council to determine what the requirements are in your region.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>How much does a chef profile cost ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                A chef profile is completely free, no upfront costs nor subscriptions fees will be asked. We only earn {{this.$store.state.parameters.find(value => value.name.name=="COMMISSION").value}}% commission on each booking (Including 3% for transaction fees).
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>I received a booking. What to do next ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                When a client makes a booking on Kootto website, you will immediately receive a notification via email. Login to your account and go to the booking section.You have {{this.$store.state.parameters.find(value => value.name.name=="REJECT_DELAY").value}} hours to confirm the booking ( keep in mind that you put yourself available on your calendar if you fail to confirm the booking, Kootto reserve the right to penalise you).<br/>
                <br/>
                Once you confirm the booking, get in contact with your client by phone or text message in order to reassure them, answer their questions and discuss the arrangements.
                Then, 2 to 3 days before the actual event in order to confirm the final details.<br/>
                <br/>
                The first calls with your clients are very important: they prove that you are a professional, reassure the guests, and allow you to define all logistic aspects of the event.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>What should you ask your clients ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                During your first call, you will need to confirm with your clients:<br/>
                <ul>
                  <li>The date and hour of the event</li>
                  <li>The equipment available on site, so that you can bring what may be missing</li>
                  <li>The details of the menu and all possible add-ons (number of guests, beverages, extra appetisers / cheese / sweets, special equipment, and any dietary requirements.)</li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>When do I get paid ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                The money will be transfer to your bank account after the service is complete within a week.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Should I inform Kootto of any booking changes ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Please contact us.<br/>
                <br/>
                If you and the client agreed on any changes to the menu, please inform us via email or phone .<br/>
<!--                so that we can print and ship the correct version of the menus-->
                <br/>
                If the client wants to book something extra :<br/>
                <br/>
                Please tell them to contact us (or contact us directly) so that we can deal with the add-on. We will bill the client the additional amount to be paid in advance, which guarantees for all payments to be made before the event.<br/>
                Please try to avoid as much as possible talking about prices directly with the client, as some confusion may happen between the net and public prices.<br/>
                <br/>
                If you have a serious issue<br/>
                or<br/>
                If you ever have a very serious problem that prevents you from going to the event, please call the team immediately. We will take care of calling the client and find a solution (either postpone the event or find another chef); please avoid calling the client yourself in order not to worry them.
              </v-expansion-panel-content>
            </v-expansion-panel>

<!--            <v-expansion-panel>-->
<!--              <v-expansion-panel-header><h3>How do clients review my work ?</h3></v-expansion-panel-header>-->
<!--              <v-expansion-panel-content>-->
<!--                The day after an event, the host and his guests will receive an email asking them to review and comment on the service. Reviews are public and are composed of a 5-stars rating and a written comment. We moderate opinions but remain impartial in the case of a negative opinion, we will not delete it.-->
<!--              </v-expansion-panel-content>-->
<!--            </v-expansion-panel>-->

            <v-expansion-panel>
              <v-expansion-panel-header><h3>What are the legal documents to Upload ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li>Chef certificate/diploma</li>
                  <li>Hygiene certificate</li>
                  <li>Proof of Identification</li>
                  <li>ABN</li>
                </ul>

              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Booking fees and commission  </h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Kootto charges a commission to the chef on each booking. The amount of this commission is the same for every type of service, {{this.$store.state.parameters.find(value => value.name.name=="COMMISSION").value}}% commission on each booking (Including 3% for transaction fees).
                We try to limit the amount of commission to ensure the profitability for chefs.<br/>
                <br/>This commission on each booking is clearly indicated in the chef back office (on each menu you create and on each booking summary).
                At the bottom of your menu we included a table showing examples of bookings breakdown.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Can Kootto pay me partially in advance ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                By default, we do not make a down payment for the services booked on Kootto.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>At what time should I arrive at the customer's place ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Once a client has booked your services, you will have his contact in the booking details. You will then have to contact your client and arrange the time of your arrival. According to your menu and your preparations you will determine how long before the service is necessary for you to be ready.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>What is expected from the chefs ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                Customers book one of your menus to have the luxury of not having to do anything. So you have to take care of everything: from the shopping all the way to the cleaning before you leave.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>What should I bring ? Equipment, tableware ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                It is your duty to bring the ingredients and all the missing equipment, as well as setting up in the kitchen and preparing the on-site menu. We highly recommend bringing your knives, pots and pans, and all other equipment that is necessary.
                Salt, pepper, oil, bread etc ... Do not leave anything to chance.<br/>
                <br/>
                After the clients confirm their booking, we ask you to contact them and discuss the equipment and serving plates they have.  Do not forget to ask for all details of the equipment (some customers forget to say that they only have 2 stoves and not 4 for example).<br/>
                <br/>The serving dishes will affect a lot the quality of your set-up. Unfortunately, the dishes of the customer are not necessarily adapted to your work. To ensure optimum quality, it may be interesting for you to bring your own dishes. Don’t let your reputation depend on the serving dishes of a customer.

              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>How should I engage with the clients ?    </h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                It is important to create a friendly and professional atmosphere. The clients should feel like guests in a restaurant. Ensure that you understood all possible allergies or dietary needs. You can talk about your background or connections with the food you are cooking. Create a unique experience for guests that will shape your reputation and will open more potential requests.

              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Who does the service ?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                It's up to you to manage the service; it is not the responsibility of the customer (unless explicitly requested by the client). If you need the help of a waiter (for example if the number of guests/number of dishes is high)
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Who does the dishes?</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                You are responsible for cleaning the dishes and for leaving the kitchen as you found it. It is a key element that recurs in the positive ratings given to your services. Kootto asks you to take a picture of the kitchen when you leave in case of dispute with the clients.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header><h3>Creating a new private chef menu.</h3></v-expansion-panel-header>
              <v-expansion-panel-content>
                It is important to create a menu that you are comfortable cooking. Kootto has designed the platform for you to be as creative as you like. You can update, change and modify some dishes during the year. We did so because we believe in fresh and local produce that you will be able to source with your supplier following the seasons.<br/>
                <br/>Your menu has to be cost-effective for you. Prices are all inclusive. They should include your time, your transport, the preparation, ingredients, service and storage. When a customer books and pays for a service with Kootto ,he will have nothing else to pay. It's up to you to provide in advance all your costs. We can offer you some level of support and tools to calculate your prices.
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>

        </v-tab-item>
      </v-tabs-items>

    </div>
  </v-container>

</template>

<script>

export default {
  name: "Help",
  components: {
  },
  data: () => ({
    panelCustomer: [0],
    panelChef: [0],
    tabs:undefined
  }),
};
</script>
